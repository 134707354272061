body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.blink_me {
  animation: blinker 1s infinite;
  background-color: #f9a825;
  color: #000 !important;
  text-transform: capitalize !important;
}
.blink_me2 {
  animation: blinker2 1s infinite;
  color: mediumseagreen !important;
  text-transform: capitalize !important;
}

.btn-wrapper .button {
  background-color: #f9a825;
  position: absolute;
  z-index: 2;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  font-size: 10px;
  transform: translate(-50%, -50%);
  color: white;
}
.btn-wrapper .bg {
  background-color: #ffeb3b;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: pulse 1.5s 1s infinite ease-in-out;
}

.btn-wrapper:hover .bg,
.btn-wrapper:active .bg,
.btn-wrapper:active .bg {
  background-color: red;
}
@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  25% {
    opacity: 0.3;
  }
  100% {
    transform: translate(-50%, -50%) scale(2.5);
    opacity: 0;
  }
}

@keyframes blinker {
  0% {
    background-color: #f9a825;
    box-shadow: 0 0 5px #f9a825;
  }
  50% {
    background-color: #f9a825;
    box-shadow: 0 0 20px #f9a825;
  }
  100% {
    background-color: #f9a825;
    box-shadow: 0 0 5px #f9a825;
  }
}

@keyframes blinker2 {
  0% {
    box-shadow: 0 0 5px #f9a825;
  }
  50% {
    box-shadow: 0 0 20px #f9a825;
  }
  100% {
    box-shadow: 0 0 5px #f9a825;
  }
}
p.firebase-emulator-warning{
  display:none !important;
}